import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { RefferalService } from 'src/app/_services/refferal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-refferar',
  templateUrl: './refferar.component.html',
  styleUrls: ['./refferar.component.css'],
})
export class RefferarComponent implements OnInit {
  private ngNavigatorShareService: NgNavigatorShareService;
  param1: any;
  refId: any;
  rCode: any;
  destinationUrl: string;
  queryParams: any;
  merchantId: any;
  refferalcode: any;
  customerId: any;
  bannerMsg: any;
  merchant: any;
  referrerValue: any;
  bImg: any;
  locationName: any;
  locationCity: any;
  customerName: any;
  welcomeOffer: any;
  thresHold: any;
  rMessage: any;
  Clicks: any;
  locationId: any;
  loader: boolean;
  imageUrl: string | ArrayBuffer;
  constructor(
    ngNavigatorShareService: NgNavigatorShareService,
    private route: ActivatedRoute,
    private service: RefferalService
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
  }

  ngOnInit(): void {
    this.param1 = this.route.snapshot.queryParams;
    this.refId = this.route.snapshot.queryParams.rId;
    this.rCode = this.route.snapshot.queryParams.referralCode;
    console.log(this.param1);
    console.log(this.refId);
    console.log(this.rCode);
    this.queryParams = {
      referralProgramId: this.refId,
      referralCode: this.rCode,
    };
    this.loader = true;

    this.service.refferal(this.queryParams).subscribe((res) => {
      console.log(res);
      this.loader = false;

      this.merchantId = res.merchantId;
      this.customerId = res.customerId;
      // console.log(res);
      // this.bMsg = res.social_media_banner_msg;
      this.bImg = res.social_media_banner_image;
      this.locationName = res.locationName;
      this.refferalcode = res.referralCode;
      // this.bannerMsg = res.social_media_banner_msg;
      this.merchant = res.merchantName;
      this.referrerValue = res.referral_reward_value;
      this.locationCity = res.locationCity;
      this.customerName = res.customerName;
      this.welcomeOffer = res.welcome_offer;
      this.thresHold = res.referrer_threshold;
      this.rMessage = res.referral_reward_value;
      this.locationId = res.locationId;
      // this.customerId = res.customerId;
      this.Clicks = {
        merchantId: this.merchantId,
        locationId: this.locationId,
      };
      this.service
        .referralClicks(this.Clicks, this.customerId, this.locationId)
        .subscribe((res) => {});
      this.destinationUrl = `${environment.APIURL}/leads?mId=${this.merchantId}&cId=${this.customerId}&lId=${this.locationId}&referralCode=${this.refferalcode}`;
    });
  }

  close() {
    window.close();
  }
  // share() {
  //   let img =
  //     'https://images.pexels.com/photos/4457973/pexels-photo-4457973.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';

  //   this.http
  //     .get(img, { responseType: 'blob' })
  //     .subscribe((imageBlob: Blob) => {
  //       // let Blob = await img.blob();
  //       const file = new File([imageBlob], 'image.jpeg', {
  //         type: 'image/jpeg',
  //       });
  //       const shareData = {
  //         // files:file
  //       };
  //       if (navigator.share) {
  //         navigator
  //           .share({
  //             title: 'Share',
  //             text: 'please read this great article:',
  //             url: img,
  //             files: [file],
  //           })
  //           .then(() => {
  //             console.log('thanks for sharing!');
  //           });
  //       } else {
  //         console.log('thanks for not sharing!');
  //       }
  //     });
  // }
  async share() {
    try {

      this.bannerMsg = `Hello, You have been invited to ${this.locationName} in Rajahmundry by ${this.customerName}. Please make your first visit to ${this.locationName} and get ${this.welcomeOffer}% OFF on your first visit. Click this link to reserve your welcomeOffer`;


      // if (this.isPngImage(this.bImg)) {
      //   let img = await fetch(this.bImg);
      //   let Blob = await img.blob();
      //   Blob = new File([Blob], 'image.png', {
      //     type: 'image/png',
      //   });
      // } else {
      //   let img = await fetch(this.bImg);
      //   let Blob = await img.blob();
      //   Blob = new File([Blob], 'image.jpg', {
      //     type: 'image/jpeg',
      //   });
      // }
      // const response = await fetch(this.bImg);
      // const blob = await response.blob();
      // const fileExtension = this.getFileExtension(this.bImg);


      // Convert the Blob to a File
      // const Blob = new File([blob], `image.jpg`, {

      //   type: blob.type,
      // });
      const sharedResponse = await this.ngNavigatorShareService
        .share({
          title: this.bannerMsg,
          text: this.bannerMsg,
          url: this.destinationUrl,
          // files: [Blob],
        })
        .then(() => console.log('Image shared successfully!'))
        .catch((error) => console.error('Error sharing image', error));
      // console.log(sharedResponse);
    } catch (error) {
      console.log('You app is not shared, reason: ', error);
    }
  }
  private getFileExtension(url: string): string {
    return url.split('.').pop()?.toLowerCase() || '';
  }
  isPngImage(url: string): boolean {
    console.log(url);
    return /\.png$/i.test(url);
  }
}
