import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { RefferalService } from 'src/app/_services/refferal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-refferalwebview',
  templateUrl: './refferalwebview.component.html',
  styleUrls: ['./refferalwebview.component.css'],
})
export class RefferalwebviewComponent implements OnInit {
  accept: boolean = true;
  refferalCredit: boolean = false;
  congrats: boolean = false;
  param1: any;
  mId: any;
  cId: any;
  referralCode: any;
  queryParams: any;
  locationId: any;
  refferalprogramId: any;
  leads: FormGroup;
  submit: boolean = false;
  destinationUrl: string;
  ngNavigatorShareService: NgNavigatorShareService;
  merchanName: any;
  error: any = '';
  leadsName: any;
  referrerValue: any;
  bMsg: any;
  bImg: any;
  locationName: any;
  bannerMsg: any;
  locationCity: any;
  welcomeOffer: any;
  thresHold: any;
  Clicks: any;
  lId: any;
  loader: boolean;
  lat: any;
  lag: any;
  imageUrl: string | ArrayBuffer;

  constructor(
    ngNavigatorShareService: NgNavigatorShareService,
    private route: ActivatedRoute,
    private service: RefferalService,
    private fb: FormBuilder
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
    this.leads = this.fb.group({
      name: ['', Validators.required],
      number: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
    });
  }

  ngOnInit(): void {
    this.param1 = this.route.snapshot.queryParams;
    console.log(this.param1);
    this.mId = this.route.snapshot.queryParams.mId;
    console.log(this.mId);
    this.cId = this.route.snapshot.queryParams.cId;
    this.lId = this.route.snapshot.queryParams.lId;
    this.referralCode = this.route.snapshot.queryParams.referralCode;
    this.queryParams = {
      merchantId: this.mId,
      locationId: this.lId,
    };
    this.loader = true;
    this.service.refferar(this.queryParams).subscribe((res) => {
      console.log(res);
      this.loader = false;

      // this.bannerMsg = res.bannerMessage;
      this.bImg = res.bannerImage;
      this.fetchAndDisplayImage(this.bImg);

      this.locationName = res.locationName;
      this.locationId = res.locationId;
      this.merchanName = res.merchantName;
      this.refferalprogramId = res.referralProgramId;
      this.referrerValue = res.refferalValue;
      this.locationCity = res.locationCity;
      //  this.customerName = res.customerName;
      this.welcomeOffer = res.welcomeOffer;
      this.thresHold = res.referrer_threshold;
      this.lat = res.lattitude.lat;
      this.lag = res.lattitude.lng;
      //  this.rMessage = res.referrer_message;
      this.Clicks = {
        customerId: this.cId,
        locationId: this.lId,
      };
      this.service
        .referraClicks(this.Clicks, this.cId, this.lId)
        .subscribe((res) => {});
    });

    let obj = {
      referralCode: this.referralCode,
    };
    this.service.referralNameCode(obj).subscribe(
      (result) => {
        console.log(result);
        this.leadsName = result.name;
      },
      (err) => {
        // this.error = err.error.message;
      }
    );
  }
  fetchAndDisplayImage(url: string): void {
    this.service.fetchImage(url).subscribe({
      next: (blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.imageUrl = reader.result;
        };
        reader.readAsDataURL(blob);
        console.log(reader.result);
      },
      error: (err) => {
        console.error('Error fetching image:', err);
      },
    });
  }
  acceptInvitation() {
    this.accept = false;
    this.refferalCredit = true;
  }
  backToRefferal() {
    this.accept = true;
    this.refferalCredit = false;
  }
  get f(): { [key: string]: AbstractControl } {
    return this.leads.controls;
  }
  exist(e: any) {
    if (e.target.value.length == 9) {
      this.error = '';
    }
    if (e.target.value.length == 10) {
      let obj = {
        mobileNumber: e.target.value,
        locationId: this.lId,
      };
      this.service.leadsExists(obj).subscribe(
        (result) => {
          console.log(result);
        },
        (err) => {
          this.error = err.error.message;
        }
      );
    }
  }
  refferalCreditBtn() {
    console.log(this.leads);
    if (this.leads.invalid) {
      this.submit = true;
    } else {
      this.loader = true;
      this.submit = false;
      let obj = {
        name: this.leads.value.name,
        mobileNumber: this.leads.value.number,
        locationId: this.lId,
        merchantId: this.mId,
        referralCode: this.referralCode,
        referralProgramId: this.refferalprogramId,
        referral_customer_id: this.cId,
      };

      this.service.leads(obj).subscribe((res) => {
        console.log(res);
        this.loader = false;

        this.refferalCredit = false;
        this.congrats = true;
        this.destinationUrl = `${environment.APIURL}/leads?mId=${this.mId}&cId=${res.leadsCustomerId}&lId=${this.lId}&referralCode=${res.referrarCode}`;
      });
    }
  }

  isNumberKey(evt: any) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }
  async share() {
    try {
      this.bannerMsg = `You are invited to ${this.locationName} in ${this.locationCity} by ${this.leads.value.name}
Visit ${this.locationName} and get ${this.welcomeOffer} on your first visit`;

      // if (this.isPngImage(this.bImg)) {
      //   let img = await fetch(this.bImg);
      //   let Blob = await img.blob();
      //   Blob = new File([Blob], 'image.png', {
      //     type: 'image/png',
      //   });
      // } else {
      //   let img = await fetch(this.bImg);
      //   let Blob = await img.blob();
      //   Blob = new File([Blob], 'image.jpg', {
      //     type: 'image/jpeg',
      //   });
      // }
      // let img = await fetch(this.bImg);

      // let Blob = await img.blob();
      // Blob = new File([Blob], 'image.jpg', {
      //   type: 'image/jpeg',
      // });
      const sharedResponse = await this.ngNavigatorShareService.share({
        title: this.bannerMsg,
        text: this.bannerMsg,
        url: this.destinationUrl,
        // files: [Blob],
      });
      console.log(sharedResponse);
    } catch (error) {
      console.log('You app is not shared, reason: ', error);
    }
  }
  // Check if the URL is a PNG image
  isPngImage(url: string): boolean {
    return /\.png$/i.test(url);
  }
}
