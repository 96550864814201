
<div class="main">
    <div>
        <div class="header">
         <p>Merchant Referrals</p>
        </div>
        <div class="mx-3">
            <div class="refer_header">
                <h3>Refer other businesses & get <span>₹500 OFF</span> on your next subsciption</h3>
            </div>
            <div class="d-flex input_stater">
                <div class="d-flex">
                    <input class="form-control icon">
                    <!-- <img src="../../../assets/images/send-2.svg"> -->
                </div>
                <div >
                    <img src="../../../assets/images/Frame 1000006540.svg">

                </div>
            </div>
            <div class="works_how mt-4">
                <p>How it works?</p>
                <ul>
                    <li>Amet minim mollit non officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</li>
                    <li>Amet minim mollit non officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</li>
                    <li>Amet minim mollit non officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</li>
                </ul>
            </div>
            <div class="card_refer">
               <div class="row">
                <div class="col">
                    <div class="reffer_now">
                        <P>Refer a friend & earn 
</P>
<h5>₹500 
</h5>
<p>
per referral

</p>
<button class="reffer_bnt">Refer now</button>

                    </div>
                </div>
                <div class="col">
                    <img src="../../../assets/images/7.svg">
                </div>
               </div>
            </div>
        </div>
    </div>
</div>