<div class="main">
<app-loader *ngIf="loader"></app-loader>
    <div *ngIf="accept">
<!-- <div class="top-nav mx-3" >
        <div class="d-flex">
            <div class="back-img mr-3 ml-2">
                <img src="../../../assets/images/back (1).png">
            </div>
            <div class="h-text">
                <h2>{{this.destinationUrl}}</h2>            </div>
        </div>
    </div> -->


    <div class="main-header mx-3 mt-2">
        <h2>{{welcomeOffer

}}% OFF on your first visit is waiting for you at {{locationName}}</h2>
       <h3>{{leadsName}} has invited you to visit <span>{{locationName}}</span></h3>

       <h3>Congratulations, you received {{welcomeOffer
}}% OFF from {{locationName}}. Please make your first vist and claim this welcome offer!</h3>
   <img [src]="this.bImg" class="img-1-s">
   
   
  <div class="d-flex justify-content-end mb-2">
      <h6>Get Directions</h6>    
      <img src="../../../assets/images/map.svg" class="get-d-1 ml-2">
  </div>  
<div class="invite">
    <p>Accept referral invitation to get {{welcomeOffer}}% OFF.
</p>
<!-- <h6>Use Referral credit when you make a first visit</h6> -->


<div class="accept-button">
    <button class="pointer " (click)="acceptInvitation()">Accept Invitation</button>
</div>
</div>
</div>
    </div>
    <div *ngIf="refferalCredit">
<div class="top-nav mx-3" >
        <div class="d-flex">
            <!-- <div class="back-img mr-3 ml-2"> -->
                <!-- <img src="../../../assets/images/back (1).png" class="pointer " (click)="backToRefferal()"> -->
            <!-- </div> -->
            <div class="h-text ml-3">
                <h2>Looking forward to see you at {{locationName}}</h2>            </div>
        </div>
    </div>


    <div class="main-header offer mx-3 mt-2">
       <h3><span>{{welcomeOffer}}% OFF</span> is waiting for you at <span>{{locationName}} </span>on your first visit</h3>

      
   

<div class="invite">
    


<div class="form-section mt-3">

    <form [formGroup]="leads">
           <div class="name-section mt-3 mb-3">
        <label>Enter Phone number</label>
        <input type="text" class="form-control" pattern="\d*" inputmode="numeric" (input)="exist($event)" formControlName="number" (keypress)="isNumberKey($event)" [maxLength]="10" placeholder="Enter your phone number">
        <div
                        *ngIf="
                          f.number.errors?.required && submit
                        "
                        class="invalid-feedback m-t d-block"
                      >
                         Phone number is required
                      </div>
        <div
                        *ngIf="
                          f.number.errors?.pattern && submit
                        "
                        class="invalid-feedback m-t d-block"
                      >
                         Phone number should contain 10 digits
                      </div>
        <div
                        *ngIf="
                          this.error != ''
                        "
                        class="invalid-feedback m-t d-block"
                      >
                         Phone number is already exists
                      </div>
    </div>
    <div class="name-section mt-1">
        <label>Enter Your Name</label>
        <input type="text" class="form-control" formControlName="name" placeholder="Enter your name">
        <div
                        *ngIf="
                          f.name.errors?.required && submit "
                        class="invalid-feedback m-t d-block"
                      >
                         Name is required
                      </div>
    </div>  
 
</form>
</div>
<p class="mt-2">Please share same phone number when you make a visit to claim the {{welcomeOffer}}% OFF
</p>
 <input type="checkbox" id="vehicle1"[checked]="true" name="vehicle1" value="Bike">
  <label for="vehicle1" class="ml-2">Terms and conditions</label><br>
<div class="accept-button">
    <button class="pointer " (click)="refferalCreditBtn()">Submit</button>
</div>
</div>
</div>
    </div>

    <div class="congrats-section mx-4" *ngIf="congrats">
    
       <img src="../../../assets/images/successfully.svg">
        <h5>Congrats, you will receive {{welcomeOffer}}% OFF from {{locationName}} </h5>
        <p>Please make your first visit and claim this discount.</p>

<h6>Looking forward to see you at {{locationName}}</h6>
<p class="text-bold add">Address :</p>
<h6>{{locationCity}}</h6>
 <a
                    target="_blank"
                    class="cursor"
                    href="http://maps.google.com/?q={{lat}},{{lag}}"
                    >Get Directions</a
                  >
    <!-- <div class="accept-button invite-f">
    <button (click)="share()" class="pointer ">Invite your friends</button>
</div> -->
<div class="close-button mt-3">
    <button class="pointer ">Close</button>
</div>
    </div>
</div>