import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Subscription } from 'rxjs';
import { RefferalService } from 'src/app/_services/refferal.service';

@Component({
  selector: 'app-teammanagmentwebview',
  templateUrl: './teammanagmentwebview.component.html',
  styleUrls: ['./teammanagmentwebview.component.css'],
})
export class TeammanagmentwebviewComponent implements OnInit {
  manageInvite: boolean = true;
  phoneNumber: boolean = false;
  verify: boolean = false;
  next: boolean = true;
  name: boolean = false;
  nextName: boolean = true;
  timer: boolean = true;
  otpVerifed: boolean = false;
  success: boolean = false;
  minutes: any;
  seconds: any;
  interval: any;
  resend: boolean = true;
  number: any;
  err: any;
  Name: any;
  otp: any;
  id: any;
  locationName: any;
  role: any;
  permisions: any;
  error: any;

  constructor(
    private service: RefferalService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.queryParams.sid;
    let obj = {
      staffId: this.id,
    };
    this.service.staffLocation(obj).subscribe((res: any) => {
      console.log(res);
      this.locationName = res.locationName;
      this.role = res.staffRole;
      this.permisions = res.permission;
    });
  }
  manage() {
    this.manageInvite = false;
    this.name = true;
  }
  resendBtn() {
    this.startTimer();
    this.timer = true;
  }
  nameNextButtton() {
    this.name = false;
    this.phoneNumber = true;
    this.next = true;

    console.log(this.number);
  }
  startTimer(): void {
    this.minutes = 1; // Set initial minutes
    this.seconds = 0; // Set initial seconds

    this.interval = setInterval(() => {
      if (this.seconds === 0) {
        if (this.minutes === 0) {
          clearInterval(this.interval); // Stop the timer when it reaches 0
          this.resend = false;
          this.timer = false;
        } else {
          this.minutes--; // Decrement minutes when seconds reach 0
          this.seconds = 59; // Reset seconds to 59
        }
      } else {
        this.seconds--; // Decrement seconds
      }
    }, 1000); // Update every second
  }
  phoneNext() {
    let obj = {
      name: this.Name,
      mobileNumber: this.number,
    };
    this.service.staffLogin(obj).subscribe(
      (res) => {
        this.phoneNumber = false;
        this.verify = true;
        this.startTimer();
      },
      (err) => {
        this.err = err.error.message;
      }
    );
  }
  verifyBackBtn() {
    this.verify = false;
    this.phoneNumber = true;
  }
  successBtn() {
    let obj = {
      otp: this.otp,
      mobileNumber: this.number,
    };
    this.service.staffOtp(obj).subscribe(
      (res) => {
        this.verify = false;
        this.success = true;
      },
      (err) => {
        this.error = err.error.message;
      }
    );
  }

  isNumberKey(evt: any) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  }

  validate(e: any) {
    this.number = e.target.value;
    if (this.number.length == 10) {
      this.next = false;
    } else {
      this.next = true;
    }
  }
  changeNumber() {
    this.verify = false;
    this.phoneNumber = true;
  }

  validateName(e: any) {
    this.Name = e.target.value;
    if (e.target.value.length) {
      this.nextName = false;
    } else {
      this.nextName = true;
    }
  }
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 5,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };

  handeOtpChange(value: string[]): void {
    console.log(value);
    this.otp = value.toString().replace(/,/g, '');
  }

  handleFillEvent(value: string): void {
    console.log(value.length);
    if (value.length == 6) {
      this.otpVerifed = true;
    }
  }
}
