 <div class="congrats-section mx-4" >
<app-loader *ngIf="loader"></app-loader>    
       <!-- <img src="../../../assets/images/successfully.svg">
        <h5>Congrats, you got ₹100 credit from </h5>
        <p>Make the first visit and claim the credit.</p> -->
<h4 class="bold">Refer your local friends to <span class="span">{{locationName}}</span> in Rajahmundry and get {{rMessage}}% OFF on your next visit!</h4>
<h6>Hello {{customerName}}</h6>
<h6>
    Invite {{thresHold}} of your local friends to <span>{{locationName}}</span> in Rajahmundry
</h6>
<h6>
     You will get <span>{{rMessage}}% OFF</span> on next visit when your friends make their first visit 
</h6>
<h6>Each friend also gets <span>{{welcomeOffer}}% OFF</span> on their
first visit.</h6>
   <img [src]="this.bImg" class="img-1-s">

    <div class="accept-button invite-f">
    <button class="pointer " (click)="share()">Invite  friends</button>
</div>
<!-- <div class="close-button mt-3">
    <a class="pointer " (click)="close()">How it works</a>
</div> -->
<p class="mt-5 pt-4"><span>PoweredBy</span> LocalFirst</p>
    </div>