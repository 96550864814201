import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReviewswebviewComponent } from './components/reviewswebview/reviewswebview.component';
import { RefferalwebviewComponent } from './components/refferalwebview/refferalwebview.component';
import { TeammanagmentwebviewComponent } from './components/teammanagmentwebview/teammanagmentwebview.component';
import { RefferarComponent } from './components/refferar/refferar.component';
import { MerchantrefferalsComponent } from './components/merchantrefferals/merchantrefferals.component';

const routes: Routes = [
  { path: '', component: RefferarComponent },
  { path: 'staff', component: TeammanagmentwebviewComponent },
  { path: 'leads', component: RefferalwebviewComponent },
  { path: 'refferar', component: RefferarComponent },
  { path: 'Merchant', component: MerchantrefferalsComponent },
  { path: '**', component: RefferarComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
