<div class="main">


    <div *ngIf="manageInvite">
    
     <!-- <div class="top-nav mx-3" >
        <div class="d-flex">
            <div class="back-img mr-3 ml-2">
                <img src="../../../assets/images/close.svg">
            </div>
            <div class="h-text">
                <h2>www.localfirstt.com/staff/invited=sfsfg</h2>            </div>
        </div>
    </div> -->


    <div class="main-header mx-3">
       <h3>You have been invited by {{this.locationName}}
to manage the LocalFirst app</h3>

   <img src="../../../assets/images/re-1.svg" class="img-1-s">
   
   <h6>What can you do as a Staff member of {{this.locationName}}</h6>
   <div class="role_manage">
    <h4>Your role : <span> {{this.role}}</span></h4>

    <ul>
        <li *ngFor="let p of permisions">You can {{p}} via the 
local first app</li>   

    </ul>
   </div>

<div class="accept-button">
    <button class="pointer " (click)="manage()">Manage {{this.locationName}}</button>
</div>
</div>
    </div>

    <div class="mx-3" *ngIf="name">

         <div class="top-nav pt-3" >
        <div>
            <div class="back-img mr-3">
                <img src="../../../assets/images/back (1).png">
            </div>
            <h6>Please enter your name to continue</h6>
        </div>
    </div>
     <label id="phone">Name</label>
<div class="form-section">
    <div class="name-section hint mb-3">
       
        <input type="text" class="form-control"(input)="validateName($event)" placeholder="Enter your name">
         <p></p>
        <h3 class="mt-4">HINT: Enter the name that your colleagues at {{this.locationName}} uses to call you </h3>
    </div>
</div>
<div class="accept-button">
    <button class="pointer " [class.disabled]="nextName == true" (click)="nameNextButtton()" [disabled]="nextName">Next</button>
</div>
    </div>
   
   <div class="mx-3" *ngIf="phoneNumber">

         <div class="top-nav pt-3" >
        <div>
            <div class="back-img mr-3">
                <img src="../../../assets/images/back (1).png">
            </div>
            <h6>Please enter your Phone number</h6>
        </div>
    </div>
     <label id="phone"> Enter Phone number</label>
<div class="form-section d-flex">
    
    <div class="d-flex">
        <img src="../../../assets/images/ph.svg">
    </div>
    <div class="name-section mb-3">
       
        <input type="text" class="form-control" [(ngModel)]="number" (input)="validate($event)" (keypress)="isNumberKey($event)" [maxLength]="10" placeholder="Enter your phone number">
    </div>
</div>
<p class="err">{{this.err}}</p>
<div class="accept-button">
    <button class="pointer " [class.disabled]="next == true" [disabled]="next" (click)="phoneNext()">Next</button>
</div>
    </div>



     <div class="mx-3" *ngIf="verify">

         <div class="top-nav pt-3" >
        <div>
            <div class="back-img mr-3" (click)="verifyBackBtn()">
                <img src="../../../assets/images/back (1).png">
            </div>
            <h6>Verification</h6>
        </div>
    </div>
     <label id="phone">We will send an OTP to the phone number</label>
<div  class="d-flex verify">
    <span class="mr-3">+91-{{this.number}}</span>
    <img src="../../../assets/images/edit.svg" class="mr-2 pointer" (click)="changeNumber()">
    <p>Change</p>
</div>
<ngx-otp-input [class.otp]="otpVerifed==true" [config]="otpInputConfig" (otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)">
</ngx-otp-input>
<p class="err">{{this.error}}</p>

<!-- <div class="d-flex timer-text justify-content-between" >
    <div class="d-flex">
        <p>Didn't receive code?</p>
        <button [disabled]="resend" (click)="resendBtn()">Resend</button>

    </div>
    <div class="d-flex" *ngIf="timer">
        <img src="../../../assets/images/timer.svg" alt="">
        <h6>{{ minutes }}:{{ seconds | number: '2.0' }}
            
        </h6>

    </div>
</div> -->
<div class="accept-button">
    <button class="pointer " [class.disabled]="next == true" [disabled]="next" (click)="successBtn()">Next</button>
</div>
    </div>
    <div *ngIf="success">
        <div class="success-page mx-3 mt-3 mb-3">
            <img src="../../../assets/images/close.svg" alt="">
            <h6 class="my-4">You are now ready to manage {{this.locationName}} </h6>
            <P>You need to download the localfirst app</P>
             <div class="download invite-f">
    <button class="pointer ">Download LocalFirst app</button>
</div>
<div class="close-button mt-2">
    <button class="pointer ">Contact us for help</button>
</div>
        </div>

    </div>
</div>