import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReviewswebviewComponent } from './components/reviewswebview/reviewswebview.component';
import { RefferalwebviewComponent } from './components/refferalwebview/refferalwebview.component';
import { TeammanagmentwebviewComponent } from './components/teammanagmentwebview/teammanagmentwebview.component';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { RefferarComponent } from './components/refferar/refferar.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './components/loader/loader.component';
import { MerchantrefferalsComponent } from './components/merchantrefferals/merchantrefferals.component';
@NgModule({
  declarations: [
    AppComponent,
    ReviewswebviewComponent,
    RefferalwebviewComponent,
    TeammanagmentwebviewComponent,
    RefferarComponent,
    LoaderComponent,
    MerchantrefferalsComponent,
  ],
  imports: [
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxOtpInputModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
