import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RefferalService {
  token: string | string[];
  constructor(private http: HttpClient, private router: Router) {}
  refferal(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.REFFERAL_URL}/referral/customers-data`;
    return this.http.get<any>(url, httpOptions);
  }
  refferar(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.REFFERAL_URL}/referral/leads-data`;
    return this.http.get<any>(url, httpOptions);
  }
  leadsExists(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.REFFERAL_URL}/referral/leads-exist`;
    return this.http.get<any>(url, httpOptions);
  }
  referralNameCode(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.REFFERAL_URL}/referral/leads-name`;
    return this.http.get<any>(url, httpOptions);
  }
  staffLocation(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.MERCHANT_URL}/location-permission`;
    return this.http.get<any>(url, httpOptions);
  }

  fetchImage(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }
  leads(data: any) {
    const url = `${environment.REFFERAL_URL}/referral/leads`;
    return this.http.post<any>(url, data);
  }
  staffLogin(data: any) {
    const url = `${environment.MERCHANT_URL}/webView/staff-login`;
    return this.http.post<any>(url, data);
  }
  staffOtp(data: any) {
    const url = `${environment.MERCHANT_URL}staff-login/verify-otp`;
    return this.http.post<any>(url, data);
  }
  referralClicks(params: any, mId: any, lid: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.REFFERAL_URL}/referral/referral-clicks?customerId=${mId}&locationId=${lid}`;
    return this.http.put<any>(url, httpOptions);
  }
  referraClicks(params: any, mId: any, lid: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.REFFERAL_URL}/referral/leads-click?customerId=${mId}&locationId=${lid}`;
    return this.http.put<any>(url, httpOptions);
  }
}
