import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reviewswebview',
  templateUrl: './reviewswebview.component.html',
  styleUrls: ['./reviewswebview.component.css']
})
export class ReviewswebviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
